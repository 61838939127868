import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import { useStyles } from './zip-search.styles'

const ZipSearch = (props) => {
  const {
    isMobile,
    formZip,
    zipError,
    handleSubmit,
    disable,
    onClearErrors,
    onZipChange,
  } = props
  const styles = useStyles()

  const clearErrors = () => {
    onClearErrors()
  }

  useEffect(() => {
    clearErrors()
  }, [])

  const handleChange = (e) => {
    clearErrors()
    const result = e.target.value.replace(/\D/g, '')
    onZipChange(result)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !disable) {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className={styles.formWrapper}>
      {isMobile ? (
        <Typography variant="overline" className={styles.zipLabelMobile}>
          Zip Code
        </Typography>
      ) : (
        ''
      )}
      <FormControl error={zipError !== ''} fullWidth className={styles.formRoot}>
        {isMobile ? (
          ''
        ) : (
          <Typography variant="subtitle2" variantMapping={{ subtitle2: 'p' }} className={styles.zipLabel}>
            Zip Code
          </Typography>
        )}
        <Input
          id="zip-form"
          type="text"
          value={formZip}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          disableUnderline
          className={styles.zipInput}
          placeholder="Enter zip code"
        />
        {isMobile ? (
          ''
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.submitBtn}
            onClick={handleSubmit}
            disabled={disable}
          >
            Submit
          </Button>
        )}
      </FormControl>
      {isMobile ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={styles.submitBtn}
          onClick={handleSubmit}
          disabled={disable}
        >
          Submit
        </Button>
      ) : (
        ''
      )}
      <Typography variant="body1" className={styles.error}>
        {zipError !== '' && zipError}
      </Typography>
    </div>
  )
}

ZipSearch.propTypes = {
  isMobile: PropTypes.bool,
  formZip: PropTypes.string,
  handleSubmit: PropTypes.func,
  zipError: PropTypes.string,
  disable: PropTypes.bool,
  onClearErrors: PropTypes.func,
  onZipChange: PropTypes.func,
}

export default ZipSearch
