import React from 'react'
import PropTypes from 'prop-types'
import Link from '@components/link'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { IconArrowExternal } from '@components/atoms/icons'
import IconArrow from '@components/atoms/cta-arrow'
import { CtaText } from '@components/atoms'
import ErrorBoundary from '@components/error/boundary'
import { useStyles } from './slider.styles'

const Slide = (props) => {
  const {
    image, headline, ctaText, imageMobile, externalLink, withPromotedSection, reference,
  } = props

  const images = {
    sm: imageMobile ? `${imageMobile.url}?fm=jpg&fl=progressive&q=80` : '',
    lg: image ? `${image.url}?fm=jpg&h=960&w=1440&fit=fill&fl=progressive&q=80` : '',
  }
  const styles = useStyles({ withPromotedSection, images })
  const linkProps = externalLink ? props : reference

  return (
    <ErrorBoundary>
      <div className={styles.slideContainer}>
        <div className={styles.slideContent}>
          <Container>
            <Link {...linkProps} headline={headline}>
              <Typography className={styles.slideHeadline} variant="h2">{headline}</Typography>
              <CtaText className={styles.ctaSlide}>
                {ctaText} {externalLink ? <IconArrowExternal className="external" fill="white" /> : <IconArrow />}
              </CtaText>
            </Link>
          </Container>
        </div>
      </div>
    </ErrorBoundary>
  )
}

Slide.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  imageMobile: PropTypes.shape({
    url: PropTypes.string,
  }),
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  externalLink: PropTypes.string,
  reference: PropTypes.shape({
    __typename: PropTypes.string,
    slug: PropTypes.string,
  }),
  withPromotedSection: PropTypes.bool,
}

export default Slide
