import algoliasearch from 'algoliasearch'
import { geocodeZip } from '@lib/geo-api'
import defaults from '@config/defaults'

const client = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_API_KEY,
)
const algoliaIndex = client.initIndex(process.env.ALGOLIA_COACH_INDEX_NAME)

const attributesToRetrieve = [
  'city',
  'state',
  'customSlug',
  'facilities',
  'profilePhoto',
  'firstName',
  'lastName',
  'displayName',
  'bookable',
  'bookingURL',
  'leadResponsive',
  'hasIntroVideo',
  'universalId',
]

let coordinates = ''

export const searchCoaches = async (
  zipCode,
  coachesToShow,
  searchRadius,
  filters = defaults.coachesFilterWithPriority,
  isCoachSearchPriorityEnabled,
) => {
  const geocodeResult = await geocodeZip(zipCode)
  if (geocodeResult) {
    coordinates = `${geocodeResult.lat}, ${geocodeResult.lng}`
  }
  const defaultAroundPrecision = 10 // meters
  const aroundPrecision = isCoachSearchPriorityEnabled ? searchRadius : defaultAroundPrecision
  const { hits, aroundLatLng } = await algoliaIndex.search('', {
    aroundLatLng: coordinates,
    aroundRadius: searchRadius,
    aroundPrecision,
    filters,
    sumOrFiltersScores: true,
    attributesToRetrieve,
    hitsPerPage: coachesToShow,
  })
  if (!hits || hits.length === 0) return { hits: [], aroundLatLng }
  return { hits, aroundLatLng }
}

export default searchCoaches
