import PropTypes from 'prop-types'
import {
  Typography, Box, Container, Grid,
} from '@material-ui/core'
import HocLazyLoad from '@components/hoc-lazy-load'
import ButtonCta from '@components/atoms/button-cta'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ErrorBoundary from '@components/error/boundary'
import SliderNew from '@components/slider-new/index'
import useStyles from './involvement.styles'
import DesktopInvolvementItem from './involvement-item-desktop'
import MobileInvolvementItem from './involvement-item-mobile'

const Involvement = (props) => {
  const {
    headline,
    description,
    ctaText,
    ctaLink,
    slides,
    nextSteps,
  } = props

  const styles = useStyles({ nextSteps })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

  return (isMobile
    ? (
      <ErrorBoundary>
        <Container>
          <Box className={styles.mobileHeadlineContainer}>
            <Typography variant="h4" variantMapping={{ h4: 'h2' }} className={styles.mobileHeadline}>
              {headline}
            </Typography>
            <Typography variant="body1" className={styles.subtitle}>
              {description}
            </Typography>
          </Box>
        </Container>
        <div pb={8} className={styles.mobileRoot}>
          <div data-testid="coaches-slider" className={styles.sliderContainer}>
            <SliderNew className={styles.slider}>
              {slides.items.map((slide, i) => {
                return <MobileInvolvementItem slide={slide} key={slide.sys.id} nextSteps={nextSteps} step={nextSteps && i + 1} />
              })}
            </SliderNew>
          </div>
          {!nextSteps ? (
            <Box className={styles.ctaBottom}>
              <ButtonCta
                className={styles.involvementCta}
                variant="text"
                color="primary"
                arrow
                href={ctaLink}
              >
                {ctaText}
              </ButtonCta>
            </Box>
          )
            : (
              <Typography variant="caption" className={styles.asterisk}>
                *If applicable.
              </Typography>
            )
          }
        </div>
      </ErrorBoundary>
    )
    : (
      <Box py={[4, 6, 8]} className={styles.root}>
        <Container>
          <Box className={styles.headlineContainer}>
            <Box className={styles.headlineLeft}>
              <Typography variant="h4" className={styles.headline}>
                {headline}
              </Typography>
              <Typography variant="body1" className={styles.subtitle}>
                {description}
              </Typography>
            </Box>
            {!nextSteps && (
              <Box className={styles.cta}>
                <ButtonCta
                  className={styles.involvementCta}
                  variant="text"
                  color="primary"
                  arrow
                  href={ctaLink}
                >
                  {ctaText}
                </ButtonCta>
              </Box>
            )}
          </Box>

          <Grid container spacing={4} justifyContent="center" className={styles.cardGrid}>
            {slides.items.map((slide, i) => (
              <Grid item key={slide.sys.id}>
                <DesktopInvolvementItem {...slide} nextSteps={nextSteps} step={nextSteps && i + 1} />
              </Grid>
            ))}
          </Grid>
          {!nextSteps ? (
            <Box className={styles.ctaBottom}>
              <ButtonCta
                className={styles.involvementCta}
                variant="text"
                color="primary"
                arrow
                href={ctaLink}
              >
                {ctaText}
              </ButtonCta>
            </Box>
          )
            : (
              <Typography variant="caption" className={styles.asterisk}>
              *If applicable
              </Typography>
            )
          }
        </Container>
      </Box>
    )
  )
}

Involvement.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  slides: PropTypes.object,
  nextSteps: PropTypes.bool,
}

Involvement.defaultProps = {}

export default HocLazyLoad(Involvement)
