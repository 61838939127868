import { makeStyles } from '@material-ui/core/styles'
import injectStyle from '@utils/injectStyle'
import def from '@config/defaults'

const keyframesCountdown = `
  @keyframes countdown {
    from {
      stroke-dashoffset: 0px
    }
    to {
      stroke-dashoffset: 113px
    }
  }`

injectStyle(keyframesCountdown)


export const useStyles = makeStyles((theme) => {
  return ({
    root: {
      position: 'relative',
    },
    sliderContainer: {
      height: `calc(100vh - ${def.redesignHeaderHeight * 3}px)`,
      display: 'flex',
      color: theme.palette.common.white,
    },
    slideHeadline: {
      maxWidth: 654,
    },
    slideContent: ({ withPromotedSection }) => ({
      height: '100%',
      padding: 'initial',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingBottom: withPromotedSection ? '202px' : '120px',
      [theme.breakpoints.up('sm')]: {
        paddingBottom: withPromotedSection ? '240px' : '100px',
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: withPromotedSection ? '260px' : '140px',
      },
    }),
    slideBottom: {
      flexDirection: 'column-reverse',
      alignItems: 'unset',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'flex-end',
      },
    },
    ctaSlide: {
      color: theme.palette.common.white,
      paddingLeft: 0,
      marginTop: 30,
      '& .external': {
        position: 'relative',
        top: 13,
        left: 4,
      },
    },
    countDown: {
      position: 'relative',
      margin: 'auto',
      height: '40px',
      width: '40px',
      paddingTop: '8px',
      // IE hack to avoid draw circle
      '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
        paddingTop: '4px',
      },
      textAlign: 'center',
    },
    circleWrapper: {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '40px',
      height: '40px',
      transform: 'rotateY(-180deg) rotateZ(-90deg)',
      // Safari hack rotateY
      '@media not all and (min-resolution:.001dpcm)': {
        '@supports (-webkit-appearance:none)': {
          transform: 'rotateZ(-90deg)',
        },
      },
    },
    circle: ({ timeAutoPlay, delay }) => ({
      strokeDasharray: '113px',
      strokeDashoffset: '0px',
      strokeLinecap: 'round',
      strokeWidth: '2px',
      stroke: 'white',
      fill: 'none',
      animation: delay && `countdown ${timeAutoPlay}s linear infinite forwards`,
      // IE hack to avoid draw circle
      '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
        stroke: 'none',
      },
    }),
    iconPlay: {
      fontSize: '40px',
    },
    buttonsContainer: {
      color: theme.palette.common.white,
      '& > button': {
        paddingBottom: 0,
      },
    },
    uiBox: {
      position: 'absolute',
      bottom: 20,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        bottom: 40,
      },
    },
    slideContainer: ({ images }) => ({
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: theme.palette.secondary.dark,
      backgroundImage: images && `${theme.palette.background.gradient}, url(${images.sm})`,
      '& h2': {
        color: theme.palette.common.white,
      },
      [theme.breakpoints.up('sm')]: {
        backgroundImage: images && `${theme.palette.background.gradient}, url(${images.lg})`,
      },
    }),
  })
})
