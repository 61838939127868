import { IconTestimonialQuote } from '@components/atoms/icons'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import useStyles from './testimonial.styles'

const TestimonialMarkup = (props) => {
  const {
    componentTheme,
    showOnMobile,
    variant,
    quote,
    author,
  } = props

  const theme = useTheme()
  const styles = useStyles({ fillOpacity: variant === 'lighttan' ? '.25' : '.15', showOnMobile })

  return (
    <>
      <Box mb={4}>
        <IconTestimonialQuote fill={componentTheme.iconFill} />
      </Box>
      <Box mb={5}>
        <Typography className={styles.srOnly} variant="h3">Testimonial: </Typography>
        <Typography style={{ color: 'currentColor' }} variant="h3" variantMapping={{ h3: 'p' }}>
          {quote}
        </Typography>
      </Box>
      {author && (
        <Typography style={{ color: 'currentColor' }} data-testid="author" variant="overline" variantMapping={{ overline: 'p' }}>
          {author}
        </Typography>
      ) }
    </>
  )
}

TestimonialMarkup.propTypes = {
  componentTheme: PropTypes.shape({
    icpmFill: PropTypes.string,
  }),
  showOnMobile: PropTypes.bool,
  variant: PropTypes.oneOf(['navy', 'lighttan', 'darktan']),
  quote: PropTypes.string,
  author: PropTypes.string,
}

TestimonialMarkup.defaultTypes = {}

export default TestimonialMarkup
