import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stayConnectedWrapper: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  stayConnected: {
    color: theme.palette.common.white,
    padding: '3.75rem',
    maxWidth: '23rem',
    [theme.breakpoints.down('md')]: {
      padding: '2.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3.75rem',
      textAlign: 'center',
    },
  },
  signupWrapper: {
    paddingLeft: '4rem',
    paddingRight: '4rem',
    backgroundColor: theme.palette.offwhite.main,
    display: 'flex',
    height: '100%',
    gap: '5rem',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      gap: '2rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '93%',
      gap: '0rem',
      marginBottom: '3.44rem',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      borderRadius: '8px',
    },
  },
  signupSection: {
    flexGrow: '2',
    flexBasis: '25rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 'auto',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  socialSection: {
    flexGrow: '1',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  h5: {
    color: theme.palette.secondary.light,
    marginBottom: '0.2rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  formInput: {
    display: 'inline-block',
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.25rem',
      textAlign: 'center',
    },
  },
  mobileDivider: {
    height: '1px',
    width: '100%',
    marginTop: '1.87rem',
    marginBottom: '1.87rem',
  },
  socialIcons: {
    display: 'flex',
    width: '100%',
    marginTop: '1.25rem',
    justifyContent: 'flex-start',
    '&>a': {
      paddingRight: '10px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    '& svg': {
      transform: 'scale(1.1)',
      color: theme.palette.primary.dark,
    },
    '& svg:hover': {
      transition: '250ms',
      color: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

export default useStyles
