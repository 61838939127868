/* eslint-disable no-nested-ternary */
import SliderNew from '@components/slider-new'
import {
  Box,
  Typography,
  Container,
  Button,
  Dialog,
  useMediaQuery,
  useTheme,
  LinearProgress,
  NoSsr,
} from '@material-ui/core'
import CoachCard from '@components/coach-card'
import ButtonCta from '@components/atoms/button-cta'
import {
  IconMarkerNew,
  IconMarkerOutlinePlusFill,
  IconClose,
  IconConnect,
} from '@components/atoms/icons'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { geocodeZip } from '@lib/geo-api'
import useStyles from './coaches-search-new.styles'
import ZipSearch from './zip-search'

const CoachesSearchNew = (props) => {
  const {
    headline,
    ctaText,
    ctaLink,
    coaches,
    loading,
    open,
    disable,
    buttonMessage,
    displayZip,
    onSubmit,
    onCurrentLocationClick,
    onOpenModal,
    onCloseModal,
    zipError,
    onClearZipErrors,
    noResults,
    onNoResults,
    source,
  } = props

  const [formZip, setFormZip] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const styles = useStyles()

  const handleZipChange = (zip) => {
    setFormZip(zip)
  }

  const handleClearErrors = () => {
    onClearZipErrors()
  }

  const handleOpenModal = () => {
    setFormZip('')
    onOpenModal()
  }

  const handleClose = () => {
    onCloseModal()
  }
  const handleCurrentLocationClick = () => {
    onCurrentLocationClick()
  }

  const handleSubmit = () => {
    onSubmit(formZip)
  }

  const handleDiffZip = () => {
    onNoResults(false)
  }

  const searchForZipCode = async (zipCode) => {
    if (!zipCode || zipCode.length < 5) { return }

    const result = await geocodeZip(zipCode)
    if (result) {
      const { lat, lng } = result
      window.datafields = {
        postalCode: zipCode,
        findacoach_geo_location: {
          lat,
          lon: lng,
        },
      }
    }
  }

  const handleRequestCoach = async () => {
    handleClose()
    await searchForZipCode(formZip)
  }

  return (
    <div className={styles.root}>
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        aria-modal="true"
        className={styles.modalContainer}
      >
        <Box className={styles.modal} data-testid="modal">
          <IconButton
            data-testid="close-modal"
            className={styles.closeIconButton}
            title="close"
            onClick={handleClose}
          >
            <IconClose className={styles.closeIcon} />
          </IconButton>
          {!noResults ? (
            <Box className={styles.content}>
              <Box className={styles.topContent}>
                <IconMarkerOutlinePlusFill className={styles.icon} />
                <Typography variant="h3" className={styles.modalTitle}>Set Your Location</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleCurrentLocationClick}
                  disabled={disable}
                  data-testid="use-location"
                >
                  {buttonMessage}
                </Button>
              </Box>
              <Box className={styles.bottomContent}>
                <Typography variant="body1" className={styles.zipSubtitle}>
                  Or enter your zip code to search for coaches in your area.
                </Typography>
                <ZipSearch
                  isMobile={isMobile}
                  handleClose={handleClose}
                  formZip={formZip}
                  zipError={zipError}
                  handleSubmit={handleSubmit}
                  disable={disable}
                  onClearErrors={handleClearErrors}
                  onZipChange={handleZipChange}
                />
              </Box>
            </Box>
          ) : (
            <div className={styles.content}>
              <Box className={styles.topContent}>
                <IconConnect className={styles.icon} />
                <Typography variant="h3" className={styles.modalTitle}>We are working on it!</Typography>
                <Typography variant="body1" className={styles.noResultsSubtitle}>
                  Sorry, there are no available coaches in your area. Would you
                  like to{''}
                  <button
                    onClick={handleDiffZip}
                    type="button"
                    className={styles.diffZip}
                  >
                    try a different zip code
                  </button>{''}
                  or view all coaches?
                </Typography>
              </Box>
              <div className={styles.noResultsCtas}>
                <Button
                  className="request-coach-button"
                  variant="contained"
                  color="primary"
                  onClick={handleRequestCoach}
                >
                  Request a coach in my area
                </Button>
                <ButtonCta
                  className={styles.coachesCta}
                  variant="text"
                  arrow
                  href={ctaLink}
                >
                  {ctaText}
                </ButtonCta>
              </div>

            </div>
          )}
        </Box>
      </Dialog>
      <Container>
        <div className={styles.headlineContainer}>
          <NoSsr>
            <div className={styles.headlineLeft}>
              <Typography
                variant="h4"
                variantMapping={{ h4: 'h2' }}
              >
                {headline}
              </Typography>
              <div className={styles.subtitleContainer}>
                <div className={styles.resultsText} aria-live="polite">
                  <IconMarkerNew
                    className={styles.iconMarker}
                    viewBox="0 -2 8 17"
                  />
                  {displayZip && (
                    <Typography
                      variant="subtitle1"
                      className={styles.displayZip}
                      aria-label={`showing results near ${displayZip}`}
                    >
                      Results Near {displayZip}.
                    </Typography>
                  )}
                </div>
                {displayZip && <div className={styles.divider} />}
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleOpenModal}
                  data-testid="change-location"
                  className={styles.changeLocationBtn}
                >
                  Change Location
                </Button>
              </div>
            </div>
          </NoSsr>
          <Box className={styles.cta1}>
            <ButtonCta
              className={styles.involvementCta}
              variant="text"
              color="primary"
              arrow
              href={ctaLink}
            >
              {ctaText}
            </ButtonCta>
          </Box>
        </div>
        {loading ? (
          <LinearProgress color="secondary" />
        ) : coaches.length > 0 ? (
          <div data-testid="coaches-slider" className={styles.sliderContainer}>
            <SliderNew className={styles.slider}>
              {coaches.map((info) => {
                return <CoachCard coachInfo={info} source={source} key={info.objectID} />
              })}
            </SliderNew>
            <Box className={styles.cta2}>
              <ButtonCta
                className={styles.involvementCta}
                variant="text"
                color="primary"
                arrow
                href={ctaLink}
              >
                {ctaText}
              </ButtonCta>
            </Box>
          </div>
        ) : (
          <div
            data-testid="no-results-page"
            className={styles.noResultsContainer}
          >
            <Typography variant="h5">
              Sorry, there are no results in your area.
            </Typography>
            <Typography variant="body1" className={styles.noResultsBody}>
              You can try a different zip code or view all coaches.
            </Typography>
            <div className={styles.noResultsCtas}>
              <ButtonCta
                className={styles.involvementCta}
                variant="contained"
                color="primary"
                href={ctaLink}
              >
                {ctaText}
              </ButtonCta>
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

CoachesSearchNew.propTypes = {
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  coaches: PropTypes.array,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  disable: PropTypes.bool,
  buttonMessage: PropTypes.string,
  onSubmit: PropTypes.func,
  onCurrentLocationClick: PropTypes.func,
  onOpenModal: PropTypes.func,
  onCloseModal: PropTypes.func,
  displayZip: PropTypes.string,
  zipError: PropTypes.string,
  onClearZipErrors: PropTypes.func,
  noResults: PropTypes.bool,
  onNoResults: PropTypes.func,
}

export default CoachesSearchNew
