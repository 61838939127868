import PropTypes from 'prop-types'
import Link from '@components/link'
import Typography from '@material-ui/core/Typography'
import useStyles from './promoted-section.styles'

const PromotedSection = (props) => {
  const { promotedLinks } = props
  const styles = useStyles()

  return (
    <div className={styles.root}>
      {promotedLinks.map((promotedLink) => {
        const { externalLink, reference } = promotedLink;
        const linkProps = externalLink ? { externalLink } : { ...reference }

        return (
          <div key={promotedLink.headline} className={styles.item}>
            <Link {...linkProps} headline={promotedLink.headline}>
              {promotedLink.image && <img src={promotedLink.image.url} alt={promotedLink.headline} height="24px" />}
              <Typography variant="h5">{promotedLink.headline}</Typography>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

PromotedSection.propTypes = {
  promotedLinks: PropTypes.array,
}

PromotedSection.defaultProps = {}

export default PromotedSection
