import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'

const CardBanner = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  position: 'absolute',
  alignSelf: 'end',
  background: '#FFBE00',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  fontFamily: theme.typography.overline.fontFamily,
  height: '30px',
  width: '180px',
  right: '-38px',
  top: '32px',
  padding: '8px',
  transform: 'rotate(42.149deg)',
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: 'normal',
  letterSpacing: 1.5,
  zIndex: 1000,
  '&:hover': {
    color: 'initial',
  },
}))

export default CardBanner
