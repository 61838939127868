import { useEffect } from 'react'

export const gtmEvent = ({ event, attributes = {} }) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push({
    event,
    ...attributes,
    _clear: true, // Prevents recursive merging of array-like attributes: https://github.com/google/data-layer-helper#preventing-default-recursive-merge
  })
}

export const useSendImpressionEvent = ({ event, attributes }) => {
  useEffect(() => {
    gtmEvent({ event, attributes })
  }, [])
}
