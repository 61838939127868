import fetch from 'isomorphic-unfetch'
import algoliasearch from 'algoliasearch'

const GEO_API_BASE = process.env.COACH_TOOLS_GRAPHQL_ENDPOINT.replace('/graphql', '/api/geo')
const algolia = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
const usCitiesIndex = algolia.initIndex('us_cities')

const _parseCoordinates = (coordinates) => {
  let formattedCoordinates

  if (typeof coordinates === 'string' || coordinates instanceof String) {
    const [lat, lng] = coordinates.split(',').map(c => c.trim())
    formattedCoordinates = { lat: parseFloat(lat), lng: parseFloat(lng) }
  } else if (typeof coordinates === 'object' || coordinates instanceof Object) {
    if ('lat' in coordinates && 'lng' in coordinates) {
      formattedCoordinates = { lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) }
    }
  }

  if (!formattedCoordinates || Number.isNaN(formattedCoordinates.lat) || Number.isNaN(formattedCoordinates.lng)) {
    /* eslint-disable-next-line no-console */
    console.error(`unsupported format for coordinates: ${JSON.stringify(coordinates)}`)
    return null
  }

  return formattedCoordinates
}

export const geocode = async ({
  street,
  city,
  state,
  zip,
}) => {
  const params = new URLSearchParams({
    ...street != null ? { street } : {},
    ...city != null ? { city } : {},
    ...state != null ? { state } : {},
    ...zip != null ? { postal_code: zip } : {},
  })

  const response = await fetch(`${GEO_API_BASE}/geocode?${params.toString()}`)
  const json = await response.json()
  return json.result
}

export const geocodeZip = async (zip) => {
  const params = new URLSearchParams({ postal_code: zip })
  const response = await fetch(`${GEO_API_BASE}/geocode?${params.toString()}`)
  const json = await response.json()
  return json.result
}

export const reverseGeocode = async (coordinates) => {
  const parsedCoordinates = _parseCoordinates(coordinates)
  if (!parsedCoordinates) return null

  const params = new URLSearchParams({ q: `${parsedCoordinates.lat},${parsedCoordinates.lng}` })
  const response = await fetch(`${GEO_API_BASE}/reverse?${params.toString()}`)
  const json = await response.json()
  if (!json.result) return null
  return {
    ...json.result,
    _geoloc: parsedCoordinates,
  }
}

export const autocompleteCity = async (query, limit = 5) => {
  const { hits } = await usCitiesIndex.search(query, { hitsPerPage: limit })
  return hits || []
}
