import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    paddingTop: 20,
    paddingBottom: 80,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: 60,
      paddingTop: 128,
    },
    '& .keen-slider__slide': {
      flex: '1 0 100%',
    },
    '& .keen-slider': {
      overflowX: 'hidden !important',
    },
  },
  bgOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,.7)',
    zIndex: 0,
  },
  maxWidth: {
    height: '100%',
    width: '100%',
    margin: '0 auto',
    maxWidth: 1440,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    '& .MuiGrid-root': {
      width: '100%',
    },
    '& .keen-slider': {
      overflowX: 'hidden !important',
    },
  },
  srOnly: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  customControl: {
    position: 'absolute',
    bottom: '-118px',
    left: 0,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      left: '12.5%',
      bottom: '-50px',
    },
  },
  gridItem: {
    zIndex: 1,
  },
}))

export default useStyles
