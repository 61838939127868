const injectStyle = (style) => {
  if (process.browser) {
    const styleElement = document.createElement('style')
    let styleSheet = null

    document.head.appendChild(styleElement)
    styleElement.appendChild(document.createTextNode(''))

    styleSheet = styleElement.sheet

    styleSheet.insertRule(style, styleSheet.cssRules.length)
  }
}

export default injectStyle
