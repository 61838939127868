import { HomeQuery } from '@layouts/home'

const Home = (props) => {
  return <HomeQuery {...props} />
}

Home.getInitialProps = async ({ query }) => {
  return ({ query })
}

export default Home
