import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import TestimonialSlider from './testimonial-slider'
import TestimonialMarkup from './testimonial-markup'
import useStyles from './testimonial.styles'
import { gtmEvent } from '@lib/gtm-utils'

const Testimonial = (props) => {
  const {
    variant,
    showOnMobile,
    collection = [],
  } = props

  const testimonialsProps = {
    ...props,
    slider: collection.length > 1,
    quote: collection[0]?.testimonial || collection[0]?.quote,
    author: collection[0]?.author,
    onSlideChange: (slide, previous) => {
      gtmEvent({ event: 'click-coach-quotes', attributes: { sequence: slide > previous ? 'next' : 'previous' } })
    }
  }

  const theme = useTheme()
  const styles = useStyles({ fillOpacity: variant === 'lighttan' ? '.25' : '.15', showOnMobile })
  const bgImgSrc = '/images/coach-testimonial-quote-bg.png'
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const componentTheme = useMemo(() => {
    if (variant === 'navy') return { bgColor: 'secondary.dark', color: 'secondary.contrastText', iconFill: theme.palette.gold.main }
    if (variant === 'darktan') return { bgColor: 'primary.dark', color: 'secondary.contrastText', iconFill: theme.palette.gold.main }
    if (variant === 'lighttan') return { bgColor: 'primary.light', color: 'text.primary', iconFill: theme.palette.primary.main }

    return { bgColor: 'secondary.dark', color: 'secondary.contrastText', iconFill: theme.palette.gold.main }
  }, [variant])

  return (
    <Box
      data-testid="testimonial-container"
      color={componentTheme.color}
      bgcolor={componentTheme.bgColor}
      style={{ paddingBottom: (collection?.length > 0 && isMobile) ? 50 : 118, backgroundImage: `url(${bgImgSrc})` }}
      className={styles.root}
    >
      <div className={styles.bgOverlay} />
      <Grid container className={styles.maxWidth}>
        <Grid item lg={8} className={styles.gridItem}>
          {testimonialsProps.slider ? <TestimonialSlider componentTheme={componentTheme} {...testimonialsProps} /> : <TestimonialMarkup componentTheme={componentTheme} {...testimonialsProps} />}
        </Grid>
      </Grid>
    </Box>
  )
}

Testimonial.propTypes = {
  variant: PropTypes.oneOf(['navy', 'lighttan', 'darktan']),
  collection: PropTypes.array,
  showOnMobile: PropTypes.bool,
}

Testimonial.defaultTypes = {
  showOnMobile: false,
}

export default Testimonial
