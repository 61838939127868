import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  item: {
    width: '33%',
    borderRight: `1px solid ${theme.palette.grey[600]}`,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: 180,
    },
    '& a': {
      display: 'block',
    },
    '& > a h5': {
      marginTop: theme.spacing(2),
      color: theme.palette.common.white,
    },
    '& > a:hover h5': {
      opacity: 0.5,
    },
    '&:first-child': {
      borderLeft: 'none',
      paddingLeft: 0,
      [theme.breakpoints.up('md')]: {
        borderLeft: `1px solid ${theme.palette.grey[600]}`,
        paddingLeft: theme.spacing(3),
      },
    },
    '&:last-child': {
      marginRight: 0,
      borderRight: 'none',
      [theme.breakpoints.up('sm')]: {
        borderRight: `1px solid ${theme.palette.grey[600]}`,
      },
    },
  },
}))

export default useStyles
