const improveYourGameFragment = `
headline
summary
slug
sys {
  publishedAt
  firstPublishedAt
  id
}
image {
  contentType
  url
  description
  height
  title
  width
}
video {
  contentType
  url
  description
  height
  title
  width
}
cat {
  sys {
      id
  }
  series
}
tagsCollection {
  items {
    sys {
      id
    }
    label
  }
}
`

export default improveYourGameFragment
