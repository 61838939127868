import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.blue.light25,
    color: theme.palette.secondary.main,
    padding: theme.spacing(4),
    overflow: 'hidden',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
  },
  headlineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '0',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      minWidth: '0',
    },
  },
  headlineLeft: {
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  cta1: {
    visibility: 'visible',
    '& a': {
      padding: '0',
    },
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
      width: '0%',
    },
  },
  cta2: {
    visibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      textAlign: 'center',
      marginTop: '1.75rem',
    },
  },
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: '10px 0',
    marginLeft: '-7px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  resultsText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      margin: '6px 0 -12px 0',
    },
  },
  iconMarker: {
    marginRight: '5px',
    '& svg': {
      '& path': {
        fill: theme.palette.text.primary,
      },
    },
  },
  divider: {
    height: '16px',
    width: '1px',
    backgroundColor: theme.palette.grey.light,
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
  },
  displayZip: {
    fontWeight: '300',
  },
  changeLocationBtn: {
    ...theme.typography.subtitle1,
    fontWeight: '300',
    color: theme.palette.text.primary,
    paddingLeft: '0',
    paddingRight: '0',
    textDecoration: 'underline',
    transition: '250ms',
    '&:hover': {
      color: theme.palette.text.primary,
      opacity: '0.7',
    },
  },
  sliderContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  slider: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
  },
  closeIcon: {
    color: theme.palette.text.primary,
    transition: '250ms',
  },
  closeIconButton: {
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    height: '50px',
    width: '50px',
    position: 'absolute',
  },
  modalContainer: {
    backgroundColor: 'rgba(0, 0, 0, .25)',
    height: '100vh',
    width: '100vw',
    '&>div': {
      '&>div': {
        width: '70%',
        height: '80%',
        maxHeight: '535px',
        minHeight: '510px',
        [theme.breakpoints.only('xs')]: {
          width: '90vw',
          height: '60vh',
          maxHeight: 'none',
        },
      },
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: '8px solid white',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    paddingBottom: theme.spacing(8),
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  bottomContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
  modalTitle: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  zipSubtitle: {
    textAlign: 'center',
  },
  noResultsSubtitle: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  diffZip: {
    ...theme.typography.body1,
    display: 'inline',
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noResultsContainer: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
  },
  noResultsBody: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  noResultsCtas: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100px',
    },
  },
  coachesCta: {
    paddingRight: 0,
  },
}))

export default useStyles
