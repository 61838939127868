import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { validateEmail, postData } from '@utils'
import { useStyles, EmailInput } from './stay-connected-form.styles'

const texts = {
  placeholder: 'Email',
  emailError: 'This Email is not valid.',
  success: 'Thanks for subscribing.',
  checkboxRequired: 'Select the checkbox to continue.',
}

const apiGatewayUrl = `${process.env.BASE_API_GATEWAY}newsletterSubscription`

const stayConnectedForm = (props) => {
  const {
    isMobile,
  } = props
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const styles = useStyles({ formSubmitted })

  const clearErrors = () => {
    if (termsError) { setTermsError(false) }
    if (emailError) { setEmailError(false) }
  }

  useEffect(() => {
    clearErrors()
  }, [termsAgreed])

  const submit = () => {
    if (!termsAgreed) {
      setTermsError(true)
      return
    }
    const errorValidation = !validateEmail(email.trim())
    setEmailError(errorValidation)
    if (termsAgreed && !errorValidation && !formSubmitted) {
      postData(apiGatewayUrl, {
        email,
        interestId: '7b089f36b0', // CoachingInterest
      })
      setFormSubmitted(true)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') { submit() }
  }

  const handleChange = (e) => {
    clearErrors()
    setEmail(e.target.value)
  }

  if (formSubmitted) {
    return (
      <Box pt={3} pb={4} className={styles.success} aria-live="polite" textAlign="center" data-cy="newsletter-form">
        {texts.success}
      </Box>
    )
  }

  return (
    <FormControl error={termsError || emailError} fullWidth className={styles.root} data-cy="newsletter-form">
      {isMobile && (
        <Typography variant="overline" className={styles.emailLabel}>Email</Typography>
      )}
      <EmailInput
        id="stay-connected-form-email-address"
        type="email"
        value={email}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        disableUnderline
        placeholder="Enter email address"
        className={styles.emailBox}
        startAdornment={(
          !isMobile && (
            <InputAdornment position="start">
              <InputLabel className={styles.emailLabel} htmlFor="stay-connected-form-email-address">
                <Typography className={styles.formLabels}>E-mail</Typography>
              </InputLabel>
            </InputAdornment>
          )
        )}
        endAdornment={(
          !isMobile
            && (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={styles.submitBtn}
                  onClick={submit}
                >
              Sign Up
                </Button>
              </InputAdornment>
            )
        )}
      />
      <FormControlLabel
        className={styles.formCheckbox}
        control={(
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="agreedToTerms"
            value={termsAgreed}
            onChange={event => setTermsAgreed(event.target.checked)}
          />
        )}
        label={(
          <span className={styles.checkboxLabel}>
            By checking this box, you are agreeing to
            PGA of America&apos;s <a target="_blank" href="/pga-of-america/privacy-policy">Privacy Policy</a> and <a target="_blank" href="/pga-of-america/terms-of-service">Terms of Service</a>.
          </span>
        )}
      />
      <FormHelperText aria-live="polite" className={styles.errorMessage}>
        {(termsError && texts.checkboxRequired) || (emailError && texts.emailError)}
      </FormHelperText>
      {isMobile
        && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.submitBtn}
            onClick={submit}
          >
      Sign Up
          </Button>
        )
      }
    </FormControl>
  )
}

stayConnectedForm.propTypes = {
  isMobile: PropTypes.bool,
}

export default stayConnectedForm
