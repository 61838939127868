import { makeStyles } from '@material-ui/core/styles'

const selectHeight = '3.75rem'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'rgba(235, 239, 245, 1)',
    paddingTop: '3.75rem',
    paddingBottom: '4.375rem',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: '2rem',
    },
  },
  headline: {
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2.87rem',
      paddingBottom: '1.25rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  headerCta: {
    padding: 0,
  },
  selectionBox: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    maxWidth: '600px',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  selectLabel: {
    ...theme.typography.formInputs,
    padding: '1rem',
    lineHeight: '1.75rem',
    whiteSpace: 'nowrap',
    height: selectHeight,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'none',
      textAlign: 'center',
    },
  },
  select: {
    width: '100%',
    height: selectHeight,
    '& div:first-child': {
      overflow: 'hidden',
      height: '100%',
      margin: '0px',
      padding: '0px',
      paddingLeft: '1rem',
      borderRadius: '0px 8px 8px 0px',
      display: 'flex',
      alignItems: 'center',
      color: ({ selectedCategory }) => (selectedCategory
        ? theme.palette.common.black
        : theme.palette.grey.dark),
      textTransform: ({ selectedCategory }) => (selectedCategory
        ? 'capitalize'
        : 'none'),
      [theme.breakpoints.down('sm')]: {
        borderRadius: '8px',
      },
    },
  },
  // Override the pop up material (Paper) component
  selectPaper: {
    borderRadius: '8px',
    '& ul': {
      padding: '0',
    },
    '& li': {
      textTransform: 'capitalize',
    },
  },
  menuItem: {
    padding: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      fontWeight: 'bold',
      color: theme.palette.common.white,
    },
  },
  divider: {
    opacity: '0.75',
    height: selectHeight,
    backgroundColor: theme.palette.grey.light,
    width: '1px',
  },
  slider: {
    padding: '30px 0px',
  },
  mCta: {
    marginTop: '1.75rem',
    display: 'flex',
    justifyContent: 'center',
  },
}))


export default useStyles
