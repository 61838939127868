import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import { slideFragment } from '@schema/slide'
import { promotedLinkFragment } from '../home.fragment'
import FallbackHero from './fallback-hero'

const backupHeroFragment = `
  backupHeroSlidesCollection(limit: 5) {
    total
    items {
      ${slideFragment}
    }
  }
  backupHeroSpeed
  useBackupHero
  promotedLinksCollection(limit: 3) {
    total
    items {
        ${promotedLinkFragment}
      }
    }
`

const QUERY = gql`
query HomepageBackupHero($preview: Boolean) {
  homepageCollection(limit: 1, preview: $preview) {
    items {
      ${backupHeroFragment}
    }
  }
}
`

export default function FallbackHeroWithData(props) {
  const { query } = props
  return (
    <Query query={QUERY} variables={query}>
      {({
        loading, error, data,
      }) => {
        if (error) {
          return <Error error={error} displayErrorType="page" />
        }
        if (loading) return <LinearProgress color="secondary" />

        const response = data
            && data.homepageCollection
            && data.homepageCollection.items[0]
        return (
          <FallbackHero {...props} {...response} />
        )
      }}
    </Query>
  )
}

FallbackHeroWithData.propTypes = {
  query: PropTypes.object,
}
