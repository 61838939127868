import { useState, useEffect } from 'react'
import { reverseGeocode } from '@lib/geo-api'
import PropTypes from 'prop-types'
import useNearestCoaches from '@hooks/useNearestCoaches'
import { flags, useFeatureFlags } from '@lib/feature-flags'
import defaults from '@config/defaults'
import { validateZip } from '@utils'
import { searchCoaches } from './lib'
import CoachesSearchNew from './coaches-search-new'

const initialButtonMessage = 'Use Current Location'
const defaultZip = '75033'

const CoachesSearchWrapper = (props) => {
  const [open, setOpen] = useState(false)
  const [zipError, setZipError] = useState('')
  const [displayZip, setDisplayZip] = useState(null)
  const [coaches, setCoaches] = useState([])
  const [buttonMessage, setButtonMessage] = useState(initialButtonMessage)
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  const [isCoachSearchPriorityEnabled] = useFeatureFlags([flags.FLAG_COACHES_SEARCH_PRIORITY])
  const coachesToShow = 10
  const searchRadius = 100 * 1609
  const { nearestCoaches, location } = useNearestCoaches(
    coachesToShow,
    searchRadius,
  )

  const filterCoaches = (coachesInput) => {
    const filtered = []
    coachesInput.forEach((coach) => {
      const match = filtered.find(({ objectID }) => objectID === coach.objectID)
      if (!match) {
        filtered.push(coach)
      }
    })
    return filtered
  }

  const filteredNearestCoaches = filterCoaches(nearestCoaches)

  const getCurrentLocationCoaches = () => {
    return { currentLocationCoaches: filteredNearestCoaches, currentCoords: location }
  }

  const handleClearZipErrors = () => {
    setZipError('')
  }

  const handleOpenModal = () => {
    setNoResults(false)
    setZipError('')
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const performSearch = async (zipInput) => {
    const filters = isCoachSearchPriorityEnabled ? defaults.coachesFilterWithPriority : ''
    const algRes = await searchCoaches(zipInput, coachesToShow, searchRadius, filters, isCoachSearchPriorityEnabled)
    const filteredHits = filterCoaches(algRes.hits)
    return filteredHits
  }

  const setCurrentLocationError = (message) => {
    setButtonMessage(initialButtonMessage)
    setLoading(false)
    setDisable(false)
    setZipError(message)
  }

  const getZip = async (locationString) => {
    const coords = locationString.split(',')
    const lat = Number(coords[0])
    const lng = Number(coords[1])
    const zipResult = await reverseGeocode({ lat, lng })
    if (!zipResult) return null
    return zipResult.postal_code
  }

  const handleCurrentLocationClick = async () => {
    setDisable(true)
    setButtonMessage('Locating...')
    const { currentLocationCoaches, currentCoords } = getCurrentLocationCoaches()
    const currentZip = await getZip(currentCoords)
    if (!currentZip) {
      setCurrentLocationError('Error getting zip code from current location')
      return
    }
    if (currentLocationCoaches.length) {
      setNoResults(false)
      setCoaches(currentLocationCoaches)
      setDisplayZip(currentZip)
      setOpen(false)
    } else {
      setNoResults(true)
    }
    setLoading(false)
    setDisable(false)
    setButtonMessage(initialButtonMessage)
  }

  const handleSubmit = async (formZip) => {
    const errorValidation = !validateZip(formZip.trim())
    if (errorValidation) {
      setZipError('Please enter a valid zip code.')
    } else {
      setDisable(true)
      const hits = await performSearch(formZip)
      if (hits.length) {
        setDisplayZip(formZip)
        setCoaches(hits)
        setNoResults(false)
        setOpen(false)
      } else {
        setNoResults(true)
      }
      setLoading(false)
      setDisable(false)
    }
  }

  const defaultSearch = async () => {
    const { currentLocationCoaches, currentCoords } = getCurrentLocationCoaches()
    if (currentLocationCoaches.length) {
      setCoaches(currentLocationCoaches)
    } else {
      const defaultHits = await performSearch(defaultZip)
      setCoaches(defaultHits)
      setDisplayZip(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (nearestCoaches && location) {
      defaultSearch()
    }
  }, [nearestCoaches, location])

  return (
    <CoachesSearchNew
      {...props}
      coaches={coaches}
      loading={loading}
      open={open}
      disable={disable}
      buttonMessage={buttonMessage}
      onSubmit={handleSubmit}
      onCurrentLocationClick={handleCurrentLocationClick}
      displayZip={displayZip}
      onOpenModal={handleOpenModal}
      onCloseModal={handleCloseModal}
      zipError={zipError}
      onClearZipErrors={handleClearZipErrors}
      noResults={noResults}
      onNoResults={setNoResults}
      source="pga homepage"
    />
  )
}

CoachesSearchWrapper.propTypes = {
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

export default CoachesSearchWrapper
