import PropTypes from 'prop-types'
import ErrorBoundary from '@components/error/boundary'
import defaults from '@config/defaults'
import { NextSeo } from 'next-seo'
import { CoachesSearchNew } from '@components/coaches-search-new'
import Involvement from '@components/involvement'
import Hero from '@components/hero-new/hero'
import ImproveYourGame from '@components/improve-your-game/improve-your-game'
import StayConnected from '@components/stay-connected/index'
import Testimonial from '@components/testimonial'
import Hidden from '@material-ui/core/Hidden'
import { Box } from '@material-ui/core'
import NoSsr from '@material-ui/core/NoSsr'

import StorySpotlightWithData from '@components/story-spotlight/story-spotlight.query'
import SrOnly from '@components/atoms/sr-only'
import useStyles from './home.styles'
import { FallbackHeroQuery } from './fallback-hero'
import { FEATURED_STORIES_QUERY } from './home.fragment'

const Home = (props) => {
  const {
    hero,
    heroSecondaryCtaText,
    heroSecondaryCtaLink,
    useBackupHero,
    promotedLinksCollection,
    featuredCoachesHeadline,
    featuredCoachesCtaText,
    featuredCoachesCtaLink,
    filteredStoriesHeadline,
    filteredStoriesCategoriesCollection,
    filteredStoriesCtaText,
    filteredStoriesCtaLink,
    testimonial,
    reachHeadline,
    reachCtaText,
    reachCtaLink,
    reachDescription,
    reachSlidesCollection,
    newsletterSignup,
    metaTitle,
    metaDescription,
    metaImage,
    query,
  } = props

  const styles = useStyles(props)

  return (
    <div className={styles.root}>
      <NextSeo
        noindex={defaults.noindexNofollow}
        nofollow={defaults.noindexNofollow}
        title={metaTitle}
        description={metaDescription}
        canonical={defaults.baseUrl}
        openGraph={{
          title: metaTitle,
          type: 'website',
          url: `${defaults.baseUrl}`,
          images: [{ url: metaImage && metaImage.url }],
        }}
      />
      <SrOnly><h1>Homepage of the PGA of America</h1></SrOnly>
      {useBackupHero && (
        <FallbackHeroQuery {...{ query }} />
      )}
      {!useBackupHero && (
        <ErrorBoundary>
          <Hero
            hero={hero}
            ctas={promotedLinksCollection}
            secondaryCtaLink={heroSecondaryCtaLink}
            secondaryCtaText={heroSecondaryCtaText}
          />
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <Box bgcolor="white" pt={[0, 0, '6.25rem']}>
          <StorySpotlightWithData
            graphqlQuery={FEATURED_STORIES_QUERY}
            parentCollection="homepageCollection"
            query={query}
          />
        </Box>
      </ErrorBoundary>
      <NoSsr>
        <ErrorBoundary>
          <CoachesSearchNew headline={featuredCoachesHeadline} ctaText={featuredCoachesCtaText} ctaLink={featuredCoachesCtaLink} />
        </ErrorBoundary>
      </NoSsr>
      {filteredStoriesCategoriesCollection
          && filteredStoriesCategoriesCollection.items
          && filteredStoriesCategoriesCollection.items.length >= 1 && (
        <ErrorBoundary>
          <ImproveYourGame
            headline={filteredStoriesHeadline}
            ctaText={filteredStoriesCtaText}
            link={filteredStoriesCtaLink}
            categoryList={filteredStoriesCategoriesCollection}
          />
        </ErrorBoundary>
      )}
      {!!testimonial && (
        <ErrorBoundary>
          <Hidden smDown>
            <Testimonial collection={[testimonial]} variant={testimonial.theme} />
          </Hidden>
        </ErrorBoundary>
      )}
      {reachSlidesCollection
          && reachSlidesCollection.items
          && reachSlidesCollection.items.length >= 1 && (
        <ErrorBoundary>
          <Involvement
            headline={reachHeadline}
            ctaText={reachCtaText}
            ctaLink={reachCtaLink}
            description={reachDescription}
            slides={reachSlidesCollection}
          />
        </ErrorBoundary>
      )}
      {!!newsletterSignup && (
        <ErrorBoundary>
          <Box className={styles.backgroundWhite}>
            <StayConnected {...newsletterSignup} />
          </Box>
        </ErrorBoundary>
      )}
    </div>
  )
}

Home.propTypes = {
  hero: PropTypes.object,
  heroSecondaryCtaText: PropTypes.string,
  heroSecondaryCtaLink: PropTypes.string,
  backupHeroSlidesCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  backupHeroSpeed: PropTypes.string,
  useBackupHero: PropTypes.bool,
  promotedLinksCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  featuredStoriesCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  featuredStoriesHeadline: PropTypes.string,
  featuredStoriesCtaText: PropTypes.string,
  featuredStoriesCtaLink: PropTypes.string,
  featuredCoachesHeadline: PropTypes.string,
  featuredCoachesCtaText: PropTypes.string,
  featuredCoachesCtaLink: PropTypes.string,
  filteredStoriesHeadline: PropTypes.string,
  filteredStoriesCategoriesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      category: PropTypes.string,
      series: PropTypes.string,
    })),
  }),
  filteredStoriesCtaText: PropTypes.string,
  filteredStoriesCtaLink: PropTypes.string,
  testimonial: PropTypes.shape({
    testimonial: PropTypes.string,
    author: PropTypes.string,
    theme: PropTypes.string,
  }),
  reachHeadline: PropTypes.string,
  reachDescription: PropTypes.string,
  reachCtaText: PropTypes.string,
  reachCtaLink: PropTypes.string,
  reachSlidesCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  newsletterSignup: PropTypes.shape({
    headline: PropTypes.string,
    emailHeadline: PropTypes.string,
    emailDescription: PropTypes.string,
    showSubscribe: PropTypes.bool,
    socialHeadline: PropTypes.string,
    socialDescription: PropTypes.string,
    socialCollection: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          link: PropTypes.string,
          headline: PropTypes.string,
        }),
      ),
    }),
  }),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  brandText: PropTypes.string,
  brandImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  brandImageMobile: PropTypes.shape({
    url: PropTypes.string,
  }),
  query: PropTypes.object,
}

Home.defaultProps = {}

export default Home
