import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    overflow: 'hidden',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.palette.shadow.cardBoxShadow,
  },

  contentContainer: ({ isStory }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: isStory ? 'flex-start' : 'center',
    alignItems: 'center',
    padding: isStory ? '1.75rem 1.75rem 0' : `2.1875rem ${theme.spacing(3)}px 1.75rem`,
    maxHeight: '249px',
  }),
  coachImage: {
    height: '4.6875rem',
    width: '4.6875rem',
    border: `2px solid ${theme.palette.grey.warm}`,
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    justifyContent: 'center',
    objectFit: 'cover',
  },
  locationTag: {
    backgroundColor: theme.palette.blue.light100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    margin: '0.8rem auto 1.2rem',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  iconMarker: {
    marginLeft: '-5px',
  },
  coachLocation: {
    color: theme.palette.blue.main,
    ...theme.typography.formTags,
  },
  coachName: {
    textAlign: 'center',
    color: theme.palette.blue.dark,
  },
  facilityName: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  ctaContainer: {
    height: '50px',
    paddingBottom: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctaButton: {
    width: '100%',
    height: '50px',
    padding: '0',
    borderTop: `1px solid ${theme.palette.grey.light}`,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.light,
    },
  },
  ctaButtonPair: {
    height: '32px',
    padding: '6px 24px',
  },
  coachTitle: {
    color: '#836F50',
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '19px',
    padding: '0.5rem 0',
  },
  bioText: {
    padding: '0 1.75rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  meetCoachButton: {
    marginBottom: '1.25rem',
  },
}))

export default useStyles
