import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    width: '80%',
    height: '80px ',
    minWidth: '400px ',
    [theme.breakpoints.only('xs')]: {
      minWidth: '0',
      marginTop: theme.spacing(8),
    },
  },
  formRoot: {
    width: '100%',
    height: '60px ',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: '8px',
    boxShadow: theme.palette.shadow.cardBoxShadow,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  },
  zipLabel: {
    color: theme.palette.primary.dark,
    width: '20%',
    textAlign: 'center',
    borderRight: '1px solid #F7F7F7',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  zipLabelMobile: {
    color: theme.palette.primary.dark,
  },
  zipInput: {
    width: '60%',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '60px',
      paddingLeft: '0',
      '&>input': {
        textAlign: 'center',
      },
    },
  },
  submitBtn: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginRight: '0',
    },
  },
  error: {
    textAlign: 'center',
    color: 'red',
    marginTop: theme.spacing(13),
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(28),
    },
  },
}))
