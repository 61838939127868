import { makeStyles, withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'


export const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  containerIcons: ({ formSubmitted }) => ({
    paddingRight: formSubmitted ? 6 : 0,
    paddingBottom: 17,
    '& .MuiIconButton-label': {
      '&::before': {
        content: '""',
        width: 7,
        height: 7,
        background: formSubmitted ? theme.palette.blue.dark : '',
        position: 'absolute',
        bottom: 19,
        right: 4,
      },
    },
  }),
  emailBox: {
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    padding: '1rem',
    marginBottom: '0.2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  emailLabel: {
    color: theme.palette.primary.dark,
    padding: '20px',
    paddingLeft: '0rem',
    borderRight: '1px solid rgba(216, 216, 216, 0.75)',
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'none',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '1.25rem',
    },
  },
  submitBtn: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  iconCheck: {
    fontSize: 14,
    position: 'absolute',
    bottom: 16,
    right: 0,
  },
  linkButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  formCheckbox: {
    marginLeft: '0px',
    '& svg': {
      color: `${theme.palette.common.black}`,
    },
    '& .MuiTypography-body1': {
      lineHeight: 1.0,
      margin: '0.5em 0',
    },
    '& a': {
      color: `${theme.palette.common.black}`,
    },
  },
  checkboxLabel: {
    fontSize: '0.75rem',
    '& a': {
      color: `${theme.palette.common.black}`,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
    '& a:hover': {
      color: `${theme.palette.primary.main}`,
      textDecoration: 'underline',
    },
  },
  formLabels: {
    ...theme.typography.formLabels,
    whiteSpace: 'nowrap',
  },
  errorMessage: {
    marginLeft: '2.375rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
}))

export const EmailInput = withStyles({
})(Input)
