import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import YouTubeIcon from '@material-ui/icons/YouTube'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { IconTikTokLogo, XIcon } from '@components/atoms/icons'
import StayConnectedForm from '@components/stay-connected-form/index'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './stay-connected.styles'

const icons = {
  instagram: InstagramIcon,
  youtube: YouTubeIcon,
  linkedin: LinkedInIcon,
  x: XIcon,
  facebook: FacebookIcon,
  tiktok: IconTikTokLogo,
}

// Social Links and Mailing List
const StayConnected = (props) => {
  const {
    headline,
    emailHeadline,
    emailDescription,
    showSubscribe,
    socialHeadline,
    socialDescription,
    socialCollection,
  } = props
  const styles = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container className={styles.container}>
      <Box className={styles.stayConnectedWrapper}>
        <Typography variant="h4" variantMapping={{ h4: 'h2' }} className={styles.stayConnected}>{headline}</Typography>
        <Box py={4} className={styles.signupWrapper}>
          {/* CMS provides a toggle for hidding the email */}
          {showSubscribe && (
            <Box className={styles.signupSection} data-cy="email-form">
              <Typography variant="h5" variantMapping={{ h5: 'h3' }} className={styles.h5}>{emailHeadline}</Typography>
              <Typography variant="body2" className={styles.formInput}>{emailDescription}</Typography>
              <StayConnectedForm
                isMobile={isMobile}
                emailDescription={emailDescription}
                showSubscribe={showSubscribe}
              />
            </Box>
          ) }
          {isMobile && showSubscribe && <Divider className={styles.mobileDivider} />}
          {socialCollection?.items
            && (
              <Box className={styles.socialSection}>
                <Typography variant="h5" variantMapping={{ h5: 'h3' }} className={styles.h5}>{socialHeadline}</Typography>
                <Typography variant="body2" className={styles.formInput}>{socialDescription}</Typography>
                <Box className={styles.socialIcons}>
                  {// eslint-disable-next-line no-shadow
                    socialCollection.items?.map(({ headline, link }) => {
                      const iconName = headline.toLowerCase()
                      const Icon = icons[iconName] || InstagramIcon
                      return (
                        <a target="_blank" rel="noopener noreferrer" href={link} title={iconName} key={headline}>
                          <Icon titleAccess={headline} />
                        </a>
                      )
                    })}
                </Box>
              </Box>
            )}
        </Box>
      </Box>
    </Container>
  )
}

StayConnected.propTypes = {
  headline: PropTypes.string,
  emailHeadline: PropTypes.string,
  emailDescription: PropTypes.string,
  showSubscribe: PropTypes.bool,
  socialHeadline: PropTypes.string,
  socialDescription: PropTypes.string,
  socialCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      headline: PropTypes.string,
    })),
  }),
}

StayConnected.defaultProps = {
  showSubscribe: true,
}

export default StayConnected
