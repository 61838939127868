import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded'
import PauseRoundedIcon from '@material-ui/icons/PauseRounded'
import ErrorBoundary from '@components/error/boundary'
import { useStyles } from './slider.styles'
import SwipeViews from './swipeViews'

const Slider = (props) => {
  const defaultSpeed = 10
  const { children, heroSpeed, promotedSection } = props
  const hasMultipleChildren = children.length > 1
  const [slideIndex, setSlideIndex] = useState(0)
  const [autoplay, setAutoplay] = useState(true)
  const [delay, setDelay] = useState(false)
  const timeAutoPlay = heroSpeed || defaultSpeed
  const styles = useStyles({ timeAutoPlay, delay })

  const handleChange = (index) => {
    setSlideIndex(index)
    setAutoplay(false)
  }

  const decreaseIndex = () => {
    setSlideIndex(slideIndex - 1)
    setAutoplay(false)
  }

  const increaseIndex = useCallback((stop = false) => {
    setSlideIndex(slideIndex + 1)
    if (!stop) {
      setAutoplay(false)
    }
  })

  useEffect(() => {
    const timerDelay = setInterval(() => {
      if (!delay) setDelay(true)
    }, 0)
    return () => {
      clearInterval(timerDelay)
    }
  }, [delay])

  useEffect(() => {
    let timer
    if (hasMultipleChildren) {
      timer = setInterval(() => {
        if (autoplay) {
          increaseIndex(true)
        }
      }, timeAutoPlay * 1000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [autoplay, hasMultipleChildren, increaseIndex])

  return (
    <ErrorBoundary>
      <div className={styles.root}>
        <SwipeViews
          disabled={!hasMultipleChildren}
          slideClassName={styles.sliderContainer}
          index={slideIndex}
          interval={timeAutoPlay}
          onChangeIndex={handleChange}
        >
          {
            React.Children.map(children, c => React.cloneElement(c))
          }
        </SwipeViews>
        <div className={styles.uiBox}>
          <Container>
            <Grid container className={styles.slideBottom}>
              {promotedSection && <Grid item xs={12} sm={8} md={6} lg={5}>{promotedSection}</Grid>}
              {hasMultipleChildren && (
                <Box
                  component={Grid}
                  item
                  xs={12}
                  md={promotedSection ? 6 : 12}
                  textAlign="right"
                  className={styles.buttonsContainer}
                >
                  <IconButton aria-label="prev" onClick={() => decreaseIndex()} color="inherit">
                    <ChevronLeftRoundedIcon fontSize="large" />
                  </IconButton>
                  <IconButton aria-label="next" onClick={() => increaseIndex()} color="inherit">
                    <ChevronRightRoundedIcon fontSize="large" />
                  </IconButton>
                  {autoplay
                    ? (
                      <IconButton aria-label="pause" onClick={() => setAutoplay(false)} color="inherit">
                        <div className={styles.countDown}>
                          <PauseRoundedIcon />
                          <svg className={styles.circleWrapper}>
                            <circle className={styles.circle} r="18" cx="20" cy="20" />
                          </svg>
                        </div>
                      </IconButton>

                    ) : (
                      <IconButton aria-label="pause" onClick={() => setAutoplay(true)} color="inherit">
                        <PlayCircleFilledWhiteRoundedIcon className={styles.iconPlay} />
                      </IconButton>
                    )}
                </Box>
              )
              }
            </Grid>
          </Container>
        </div>
      </div>
    </ErrorBoundary>
  )
}

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  promotedSection: PropTypes.node,
  heroSpeed: PropTypes.number,
}

export default Slider
