import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import Home from './home'
import homeFragment from './home.fragment'

const QUERY = gql`
query Homepage($preview: Boolean) {
  homepageCollection(limit: 1, preview: $preview) {
    items {
      ${homeFragment}
    }
  }
}
`
export default function HomeWithData(props) {
  const { query } = props

  return (
    <Query query={QUERY} variables={query}>
      {({
        loading, error, data,
      }) => {
        if (error) {
          return <Error error={error} displayErrorType="page" />
        }
        if (loading) return <LinearProgress color="secondary" />
        const response = data && data.homepageCollection && data.homepageCollection.items[0]
        return (
          <Home {...props} {...response} />
        )
      }}
    </Query>
  )
}

HomeWithData.propTypes = {
  query: PropTypes.object,
}
