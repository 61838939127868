
import PropTypes from 'prop-types'
import SliderNew from '@components/slider-new'
import TestimonialMarkup from './testimonial-markup'
import { useTheme } from '@material-ui/core/styles'
import useStyles from './testimonial.styles'


const TestimonialSlider = (props) => {
  const {
    collection,
    componentTheme,
    showOnMobile,
    variant,
    quote,
    author,
    onSlideChange,
  } = props

  const theme = useTheme()
  const styles = useStyles({ fillOpacity: variant === 'lighttan' ? '.25' : '.15', showOnMobile })
  const config = {
    initial: 0,
    loop: true,
    mode: 'snap',
    slides: { origin: 'center', perView: 1, spacing: 8 },
    breakpoints: {
      '(min-width: 600px)': {
        loop: false,
        slides: { perView: 'auto', spacing: 200 },
      },
    },
  }

  return (
    <>
      <SliderNew
        controlClassName={styles.customControl}
        config={config}
        onSlideChange={onSlideChange}
      >
        {collection && collection.map((testimonial, i) => (
          <div key={`testimonial-key${i}`} className={styles.slideWrapper}>
            <TestimonialMarkup quote={testimonial.testimonial} author={testimonial.author} variant={testimonial.theme} componentTheme={componentTheme} />
          </div>
        ))}

      </SliderNew>
    </>
  )
}
TestimonialSlider.defaultTypes = {
  collection: PropTypes.array,
  componentTheme: PropTypes.object,
  showOnMobile: PropTypes.boolean,
  variant: PropTypes.string,
  quote: PropTypes.string,
  author: PropTypes.string,
}

export default TestimonialSlider
