import ErrorBoundary from '@components/error/boundary'
import PromotedSection from '@components/promoted-section/index'
import Slider, { Slide } from '@components/slider'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

export default function FallbackHero({
  backupHeroSlidesCollection,
  promotedLinksCollection,
  backupHeroSpeed,
  useBackupHero,
}) {
  return (
<>
    {!!useBackupHero && (
      <ErrorBoundary>
        <Box data-cy="home-page-hero">
          {backupHeroSlidesCollection.total > 0 && (
            <Slider
              heroSpeed={Number(backupHeroSpeed)}
              promotedSection={<PromotedSection promotedLinks={promotedLinksCollection.items} />}
            >
              {backupHeroSlidesCollection.items.map(
                slide => (
                  <Slide
                    withPromotedSection={promotedLinksCollection.items.length > 0}
                    key={slide.headline}
                    {...slide}
                  />
                ),
              )}
            </Slider>
          )}
        </Box>
      </ErrorBoundary>
    )}
</>
  )
}

FallbackHero.propTypes = {
  backupHeroSlidesCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  promotedLinksCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  backupHeroSpeed: PropTypes.string,
  useBackupHero: PropTypes.bool,
}
