import React from 'react'
import PropTypes from 'prop-types'
import virtualize from 'react-swipeable-views-utils/lib/virtualize'
import bindKeyboard from 'react-swipeable-views-utils/lib/bindKeyboard'
import SwipeableViews from 'react-swipeable-views'

const VirtualizeSwipeViews = bindKeyboard(virtualize(SwipeableViews))

// modulo that supports negative numbers (so that e.g. -5 % 4 = 3)
export const modulo = (a, n) => ((a % n) + n) % n

const carouselSlideRenderer = children => ({ index, key }) => React.cloneElement(
  children[modulo(index, children.length)], { key },
)

const SwipeViews = ({ children, ...other }) => {
  const slideRenderer = carouselSlideRenderer(children)
  return (
    <VirtualizeSwipeViews
      {...other}
      slideRenderer={slideRenderer}
    />
  )
}

SwipeViews.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
}

export default SwipeViews
