import React, { useState } from 'react'
import ErrorBoundary from '@components/error/boundary'
import PropTypes from 'prop-types'
import {
  Container, Box, Typography,
} from '@material-ui/core'
import ButtonCta from '@components/atoms/button-cta'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import CardFeaturedStory from '@components/card-featured-story/'
import SliderNew from '@components/slider-new'
import { useQuery } from '@apollo/client'

import gql from 'graphql-tag'
import LinearProgress from '@material-ui/core/LinearProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './improve-your-game.styles'
import improveYourGameFragment from './improve-your-game.fragment'
import { gtmEvent } from '@lib/gtm-utils'

const QUERY = gql`
query StoriesBySeriesId($where: StoryFilter) {
    storyCollection(
      limit: 5
      where: $where
      order: [sys_firstPublishedAt_DESC]
      ) {
    items {
      ${improveYourGameFragment}
    }
  }
}
`

const ImproveYourGame = (props) => {
  const {
    headline,
    ctaText,
    link,
    categoryList,
  } = props
  const [selectedCategory, setSelectedCategory] = useState('')
  const styles = useStyles({ selectedCategory })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // On load fetch the 5 most recent articles that match any of the "learning" series
  const defaultQuery = categoryList.items.reduce((prev, curr) => {
    prev.push({ cat: { sys: { id: curr.sys.id } } })
    return prev
  }, [])
  const [orQuery, setOrQuery] = useState(defaultQuery)

  const { data, loading } = useQuery(QUERY, {
    variables: {
      where: { OR: orQuery },
    },
    ssr: true,
  })

  const handleChange = (event) => {
    const category = event.target.value
    gtmEvent({
      event: 'filter-golf-tips',
      attributes: { tips_filter: category.series }
    })
    setOrQuery({ cat: { sys: { id: category.sys.id } } })
    setSelectedCategory(category)
  }

  return (
    <ErrorBoundary>
      <Box className={styles.root}>
        <Container className={styles.container}>
          <Box className={styles.headerLine}>
            <Typography
              variant="h4"
              variantMapping={{ h4: 'h2' }}
              className={styles.headline}
            >{headline}
            </Typography>
            {!isMobile && <ButtonCta className={styles.headerCta} href={link} variant="text" color="primary" arrow>{ctaText}</ButtonCta>}
          </Box>

          {isMobile && (
            <Typography variant="overline" className={styles.selectLabel}>I am a...</Typography>
          )}
          <Box className={styles.selectionBox} mb={6}>
            {!isMobile && (
              <>
                <Typography className={styles.selectLabel}>
                  I am a...
                </Typography>
                <Box className={styles.divider} />
              </>
            )}
            <Select
              labelid="category-select-label"
              labelWidth={0}
              aria-label="Dropdown category select"
              role="listbox"
              value={selectedCategory}
              onChange={handleChange}
              className={styles.select}
              disableUnderline
              displayEmpty
              renderValue={value => (value ? value.series : 'Select a topic')}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: { paper: styles.selectPaper },
              }}
            >
              {categoryList.items
                && categoryList.items.map((cat) => {
                  return (
                    <MenuItem
                      className={styles.menuItem}
                      key={cat.sys.id}
                      value={cat}
                    >{cat.series}
                    </MenuItem>
                  )
                })}
            </Select>
          </Box>

          {loading && <LinearProgress color="secondary" />}

          {data?.storyCollection?.items && data.storyCollection.items.length > 0 && (
            <SliderNew className={styles.slider}>
              {data.storyCollection.items.map(item => <CardFeaturedStory key={item.sys.id} {...item} />)}
            </SliderNew>
          )}

          {isMobile && (
            <Box className={styles.mCta}>
              <ButtonCta href={link} variant="text" color="primary" size="small" arrow>{ctaText}</ButtonCta>
            </Box>
          )}
        </Container>
      </Box>
    </ErrorBoundary>
  )
}

ImproveYourGame.propTypes = {
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  link: PropTypes.string,
  categoryList: PropTypes.shape({
    items: PropTypes.array,
  }),
}

ImproveYourGame.defaultProps = {}

export default ImproveYourGame
