import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  reachDesc: {
    textAlign: 'center',
    margin: `${theme.spacing(4)}px auto ${theme.spacing(8)}px`,
    maxWidth: 640,
  },
  eventStatusWrap: {
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px 0`,
    display: 'flex',
    alignItems: 'center',
  },
  eventStatus: {
    display: 'inline-flex',
    position: 'relative',
    top: 0,
  },
  eventDivider: {
    display: 'inline-flex',
    margin: `8px 0 ${theme.spacing(1)}px 0`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    width: 40,
    height: 1,
  },
  eventHeadline: {
    fontSize: 16,
    display: 'inline-flex',
  },
  eventHeader: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  backgroundWhite: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

export default useStyles
